import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import IconExternal from '../../../static/images/external_link.svg'

const ServicesContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 70rem;
  padding-top: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: column;
    margin-top: -2rem;
  }
`
const ServiceContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 30rem;
  height: 16rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    width: initial;
    height: 100%;
    justify-content: center;
    margin: 1rem 0;
  }
`
const ServiceItemContainer = styled.div`
  /* border: 1px dotted #ccc; */
  display: flex;
  flex-direction: ${props => props.rows ? 'column' : 'row'};
  padding: 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0.75rem 0.25rem;
  }
`
const Divider = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 2.5rem;
  border: 2px solid
    ${props =>
      props.topRow ? props.theme.colors.orange : props.theme.colors.green};
  background: ${props =>
    props.topRow ? props.theme.colors.orange : props.theme.colors.green};
`
const TitleSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.75rem;
`
const BoldSpan = styled.span`
  font-weight: 600;
`
const DescSpan = styled.span`
  /* border: 1px dotted #ccc; */
  color: ${props => props.theme.colors.text};
  word-break: keep-all;
  text-align: center;
  line-height: 1.25rem;
  max-width: 25rem;
`
const TextLink = styled(Link)`
  /* border: 1px dotted #ccc; */
  margin-right: auto;
  text-decoration: none;
  color: ${props =>
    props.topRow ? props.theme.colors.orange : props.theme.colors.green};
  font-weight: 600;
  padding: 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0.5rem;
  }
`
const TextLinkExternal = styled.a`
  /* border: 1px dotted #ccc; */
  margin-right: auto;
  text-decoration: none;
  color: ${props =>
    props.topRow ? props.theme.colors.orange : props.theme.colors.green};
  font-weight: 600;
  padding: 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    padding: 0 0.5rem;
  }
`
const MapLink = styled.a`
  display: flex;
  align-items: end;
  padding-bottom: 3px;
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
`
const LinkExternal = styled.a`
  display: inline;
  text-decoration: none;
  color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
`

const Services = props => (
  <ServicesContainer>
    <ServiceContainer>
      <ServiceItemContainer>
        <Divider topRow />
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TitleSpan>
          <BoldSpan>주일예배</BoldSpan> | 2PM SUN
        </TitleSpan>
      </ServiceItemContainer>
      <ServiceItemContainer>
          <DescSpan>장소: <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">New Life Church (Dublin Campus)<br />6444 Sierra Ct, Dublin, CA 94568</LinkExternal></DescSpan>      
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TextLink topRow to={`/info/services`}>주일예배 안내 {`>`}</TextLink>
        <TextLinkExternal topRow href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">지도보기 {`>`}</TextLinkExternal>
      </ServiceItemContainer>
    </ServiceContainer>
    <ServiceContainer>
      <ServiceItemContainer>
        <Divider topRow />
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TitleSpan>
          <BoldSpan>새벽예배</BoldSpan> | 5:30AM  <br />TUE - FRI
        </TitleSpan>
      </ServiceItemContainer>
      <ServiceItemContainer>
      <DescSpan>장소: <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">New Life Church (Dublin Campus)<br />6444 Sierra Ct, Dublin, CA 94568</LinkExternal></DescSpan>      
      </ServiceItemContainer>
      <ServiceItemContainer>
      <TextLinkExternal topRow href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">지도보기 {`>`}</TextLinkExternal>
      </ServiceItemContainer>
    </ServiceContainer>
    <ServiceContainer>
      <ServiceItemContainer>
        <Divider />
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TitleSpan>
          <BoldSpan>금요 찬양 예배</BoldSpan> | 7:30PM <br />
          매달 마지막 금요일
        </TitleSpan>
      </ServiceItemContainer>
      <ServiceItemContainer rows>
        <DescSpan>날짜 및 장소: TBA</DescSpan>
        {/* <DescSpan>장소: <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">SRPC</LinkExternal></DescSpan> */}
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TextLinkExternal topRow href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">지도보기 {`>`}</TextLinkExternal>
      </ServiceItemContainer>
    </ServiceContainer>
    <ServiceContainer>
      <ServiceItemContainer>
        <Divider />
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TitleSpan>
          <BoldSpan>목요 예배</BoldSpan> | 10AM THUR
        </TitleSpan>
      </ServiceItemContainer>
      <ServiceItemContainer>
        <DescSpan>장소: <LinkExternal href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">New Life Church (Dublin Campus)<br />6444 Sierra Ct, Dublin, CA 94568</LinkExternal></DescSpan>      
      </ServiceItemContainer>
      <ServiceItemContainer>
        <TextLinkExternal topRow href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">지도보기 {`>`}</TextLinkExternal>
      </ServiceItemContainer>
    </ServiceContainer>
  </ServicesContainer>
)

export default Services
